import React from 'react'
import { imgImport, _storage } from '../../helpers/helper';
import { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import { fetchProduct, latestSeenAction } from "../../store/actions";
import BreadCumps from '../../components/BreadCumps'
import { ScrollWrapper } from '../../components/ScrollWrapper';
import { useParams } from 'react-router-dom'
import Title from '../../components/Title'
import Card from '../../components/Card'
import '../../style/pages/card-details.css'
import { useLocation, useNavigate } from 'react-router';
import { postCommentAction } from '../../store/actions';
import Hamburger from '../../components/Hamburger';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import { isInCartAction } from '../../store/reducers';

const CardDetail = () => {
	const {
		id
	} = useParams()

	const location = useLocation();
	const navigate = useNavigate()
	const dispatch = useDispatch()
	// const [rating, setRating] = useState(0)
	const [count, setCount] = useState(1)
	const [rating, setRating] = useState([])
	const [notRated, setNotRated] = useState([])
	const [recommendation, setRecommendation] = useState(0)
	const [product, setProduct] = useState({})
	const [similarProducts, setSimilarProducts] = useState([])
	const [isInCart, setIsInCart] = useState(false)
	const [comment, setComment] = useState({})
	const [showComment, setShowComment] = useState(false)
	const [err, setErr] = useState(false)
	const [sameProduct, setSameProduct] = useState(false);
	const [newComment, setNewComment] = useState({
		text: '',
		rating: '',
		title: ''
	})
	//New
	const [showModal, setShowModal] = useState(false);
	const callModal = () => setShowModal(true);
	const [callback, setCallBack] = useState({
		name: '',
		phone: '+7',
		comment: ''
	})
	let [currentImage, setCurrentImage] = useState(null);

	useEffect(() => {

		dispatch(fetchProduct(id)).then(res => {
			setSimilarProducts(res.random)
			let rate = 0
			let rated = []
			let notrated = []
			if (res.comments.length > 0) {
				res.comments.forEach(item => {
					item.rating > 3 && setRecommendation(recommendation + 1)
					rate = rate + item.rating
				})
				for (let i = 0; i < Math.round(rate / res.comments.length); i++) {
					rated.push(i)
				}
				for (let i = rated.length; i < 5; i++) {
					notrated.push(i)
				}
				setComment(res.comments)
				setRating(rated)
				setNotRated(notrated)
				setSameProduct(false)
			}
		})
	}, [sameProduct, currentImage])

	useEffect(() => {
		dispatch(fetchProduct(id)).then(res => {
			// (JSON.parse(_storage.get('cart')))
			if (_storage.get('cart')) {
				JSON.parse(_storage.get('cart')).forEach(item => {
					(item.id == res.product.id) && setIsInCart(true)
				})
			}
			dispatch(latestSeenAction(res.product))
			setProduct(res.product)
			setSameProduct(false)
		})
	}, [sameProduct, currentImage])

	const setStorage = () => {
		let item = {
			id: product.id,
			count
		}
		if (!_storage.get('cart')) {
			_storage.set('cart', JSON.stringify([item]))
		} else {
			let cart = []
			let jsonCart = JSON.parse(_storage.get('cart'))
			cart = jsonCart
			cart.push(item)
			_storage.set('cart', JSON.stringify(cart))
		}
		dispatch(isInCartAction(item))
		setIsInCart(true)
	}
	const sendComment = () => {
		if (!_storage.get('smartg-token')) {
			navigate('/login')
		}
		if (!newComment.text || !newComment.title && !newComment.rating) {
			setErr(true)
		} else {
			dispatch(postCommentAction(newComment, product.id)).then(res => {
				setShowComment(false)
			})
		}
	}

	return (
		<div>
			{showComment &&
				<div className="modal-bg">
					<div className="modal-comment-inner">
						<img onClick={() => setShowComment(false)} className='cross-img' src={imgImport('cardDetails', 'cross.png')} alt="" />
						<h1 style={{ fontSize: '30px' }}>Оставить отзыв</h1>
						<input onInput={(e) => setNewComment({ ...newComment, title: e.target.value })} placeholder='Оглавление комментария' type="text" className="comment-title-input" />
						{
							(!newComment.title && err) && <span style={{ color: 'red' }}>Введите название</span>
						}
						<input onInput={(e) => setNewComment({ ...newComment, text: e.target.value })} placeholder='Описание отзыва' type="text" className="description-input" />
						{
							(!newComment.text && err) && <span style={{ color: 'red' }}>Введите комментарий</span>
						}
						<input value={newComment.rating} onInput={(e) => e.target.value <= 5 ? setNewComment({ ...newComment, rating: e.target.value }) : ''} placeholder='Рейтинг' type="number" className="comment-rating-input" />
						{
							(!newComment.rating && err) && <span style={{ color: 'red' }}>Введите рейтинг(не больше 5)</span>
						}
						<div onClick={() => sendComment()} className="make-order">Оставить отзыв</div>
					</div>
				</div>
			}
			<div className="container">
				<Hamburger />
				<BreadCumps
					items={
						[
							{
								link: "/",
								name: 'Главная'
							},
							{
								link: `product/${product.id}`,
								name: product.title
							},
						]
					}
				/>
				<div className="product-info">
					<div className="product-photos">
						<AwesomeSlider bullets={false} buttons={true}
							onTransitionRequest={() => setCurrentImage(null)} >
							{
								product?.image?.map(item => {
									return (
										<img key={item} style={{ objectFit: 'contain', backgroundSize: "cover", backgroundRepeat: "no-repeat" }} className='main-image' data-src={currentImage ? currentImage : 'https://api.pittools.kz/storage/' + item} />
									)
								})
							}
						</AwesomeSlider>
						<div className='_mini_sliders'>
							<img onClick={(item) => {
								setCurrentImage(item.target.src)
							}} className='_mini_sliders_imag' src={product.image && 'https://api.pittools.kz/storage/' + product.image[0]} alt="" />
							<img onClick={(item) => {
								setCurrentImage(item.target.src)
							}} className='_mini_sliders_imag' src={product.image && 'https://api.pittools.kz/storage/' + product.image[1]} alt="" />
							<img onClick={(item) => {
								setCurrentImage(item.target.src)
							}} className='_mini_sliders_imag' src={product.image && 'https://api.pittools.kz/storage/' + product.image[2]} alt="" />
						</div>
					</div>
					<div className="product-info-inner">
						<p className="product-title">{product.title}</p>
						<div className="product-row">
							<div className="rating">
								<div className="rating-container-flex">
									<p className="rating-title">Рейтинг: </p>
									<div className="rating-inner">
										{
											rating.map(item => {
												return (
													<img src={imgImport('cardDetails', 'gold-star.png')} alt="" />
												)
											})
										}
										{
											notRated.map(item => {
												return (
													<img src={imgImport('cardDetails', 'gray-star.png')} alt="" />
												)
											})
										}
									</div>
								</div>
								<p className="article">Артикул: {product.setNumber}</p>
							</div>
						</div>
						<div className="price">
							<div className={product.salePrice ? 'old-price' : 'price'}>{product.price} тг.</div>
							{
								product.salePrice && <div className="price">{product.salePrice} тг.</div>
							}
						</div>
						<div className="count">
							<div className="decrease-count" onClick={() => { count > 1 && setCount(count - 1) }}>-</div>
							<div className="product-count">{count}</div>
							<div className="increase-count" onClick={() => setCount(count + 1)}>+</div>
						</div>
						<div className='_two_cards_with_one' style={{ display: "flex", justifyContent: "space-between" }}>
							{
								isInCart ?
									<button onClick={() => navigate('/basket')} className="remove-cart-btn">
										<img style={{ marginRight: '10px', width: '20px' }} src={imgImport('cardDetails', 'check.png')} alt="" />
										Уже в корзине
									</button>
									:
									<button className="add-cart-btn" onClick={() => setStorage()}>
										<img style={{ marginRight: '20px' }} src={imgImport('header', 'cart.png')} alt="" />

										Добавить в корзину
									</button>
							}
							<div>
								<button className="add-cart-btn" onClick={() => callModal()}>
									Уведомить о поступлении
								</button>
							</div>
						</div>
						{
							showModal &&
							<div className="modal-bg" style={{ position: "fixed", left: "0", bottom: "0" }}>
								<div className="modal-comment-inner">
									<img onClick={() => setShowModal(false)} className='cross-img' src={imgImport('cardDetails', 'cross.png')} alt="" />
									<h1 style={{ fontSize: '30px', color: 'black' }}>Уведомить о поступлении</h1>
									<input placeholder='Ваше имя' type="text" className="comment-title-input" />
									{
										(!callback.name && err) && <span style={{ color: 'red' }}>Введите ваше имя</span>
									}
									<input placeholder='Ваш номер' type="text" className="comment-rating-input" />
									{
										(!callback.name && err) && <span style={{ color: 'red' }}>Введите номер</span>
									}
									<textarea placeholder='Комментарий' type="text" className="comment" />
									{
										(!callback.name && err) && <span style={{ color: 'red' }}>Введите комментарий</span>
									}
									<button style={{ marginTop: '1rem', marginRight: '0' }} className="make-order">Отправить</button>
								</div>
							</div>
						}
						<div style={{ width: '100%', height: '4px', marginTop: '50px' }} className="product-line"></div>
						<div className="characteristics-cont">
							<p className="char-title">Характеристики</p>
							<div className="characteristics">
								{
									product?.characteristic?.map(item => {
										// let value = '';
										// product?.characteristic?.forEach(nextItem => {
										// 	if (item.name === nextItem?.name) value = nextItem?.value;
										// })
										return (
											<div key={item.id} className='_products_characters'>
												<div className='_product_articule'>
													{item.name}
												</div>
												<div className='_product_value'>
													{item.value}
												</div>
											</div>
										)
									})
								}
							</div>
						</div>
					</div>
				</div>
				<div style={{ width: '100%', height: '4px', marginTop: '60px' }} className="product-line"></div>
				<div className="description-container">
					<p className="product-description-title">Описание</p>
					<div className="product-description" dangerouslySetInnerHTML={{ __html: product.description }}></div>
				</div>
				<div style={{ width: '100%', height: '4px', marginTop: '60px' }} className="product-line"></div>
				<Title title="Похожие Товары"></Title>
				<div className="similar-products">
					{similarProducts && similarProducts.map((item, index) => {
						return (
							<div className='mob-product' key={item.id} onClick={() => setSameProduct(true)}>
								{
									(index <= 3) &&
									<Card
										id={item.id}
										key={item.id}
										title={item.title}
										rating={item.rating}
										image={item.image}
										price={item.price}
										salePrice={item.salePrice ? item.salePrice : ''}
										article={item.setNumber}
										created={item.created_at}
									/>
								}
							</div>
						)
					})}
				</div>
			</div>
		</div>
	)
}

export default ScrollWrapper(CardDetail)
