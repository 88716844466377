import React from 'react'
import { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import Card from '../../components/Card'
import BreadCumps from '../../components/BreadCumps'
import Hamburger from '../../components/Hamburger';
import Title from '../../components/Title'
import { fetchObjectProduct } from '../../store/actions'
import { ScrollWrapper } from '../../components/ScrollWrapper';
import '../../style/pages/comparison.css'
import { _storage, imgImport } from '../../helpers/helper';
import api from '../../helpers/api';

const Comparison = () => {
	const dispatch = useDispatch()
	const [comparisonItems, setComparisonItems] = useState([]);
	const [inCompare, setInCompare] = useState(false);
	const [deleteCompare, setDeleteCompare] = useState(false);

	useEffect(() => {
		let storage = _storage.get('smartg-comp') ? JSON.parse(_storage.get('smartg-comp')) : [];
		setInCompare(true);
		if (typeof storage == 'object' && storage.length > 0) {
			const promises = storage.map((item) => {
				return api._fetchObjectProduct(item)
			})
			const products = [];
			Promise.all(promises).then((responses) => {
				responses.forEach((res) => {
					products.push(res.data.product)
				})
				setComparisonItems(products)
			})
		}
		else {
			dispatch(fetchObjectProduct(storage)).then(res => {
				setComparisonItems(prevState => {
					return [...prevState, res.product]
				})
			})
		}
	}, [deleteCompare])

	// let comparisonItemsMutated = [];
	let comparisonItemsMutated = comparisonItems;

	if (comparisonItems.length >= 2) {
		let hasMoreCharacteristics = { index: 0, count: 0 };
		comparisonItems.forEach((item, index) => {

			if (item.characteristic.length > hasMoreCharacteristics.count) {

				hasMoreCharacteristics = { index, count: item.characteristic.length };

			}
		});

		const hasMoreCharacteristicItem = comparisonItems[hasMoreCharacteristics.index].characteristic;

		comparisonItemsMutated = JSON.parse(JSON.stringify(comparisonItems)).map((item, index) => {
			if (item.characteristic.length === 0 && index !== hasMoreCharacteristics.index) {

				for (let i = 0; i < hasMoreCharacteristicItem.length; i++) {
					item.characteristic.push({ id: hasMoreCharacteristicItem.id, name: '', value: '' })
				}
				return item;
			}

			if (item.characteristic.length >= 1 && index !== hasMoreCharacteristics.index) {

				const newCharcteristics = JSON.parse(JSON.stringify(hasMoreCharacteristicItem))

				for (let i = 0; i < newCharcteristics.length; i++) {
					const currentCharcteristic = newCharcteristics[i];
					const findItem = item.characteristic.find(ch => ch.name === currentCharcteristic.name);

					if (findItem?.name === currentCharcteristic.name) {
						newCharcteristics[i].value = findItem.value;
					} else {
						newCharcteristics[i].name = '';
						newCharcteristics[i].value = '';
					}

					item.characteristic.forEach(ch => {
						for (const key in Object.keys(ch)) {
							if (newCharcteristics[i][key] === undefined) {
								newCharcteristics[i][key] = ch[key];
							}
						}
					})
				}

				item.characteristic = newCharcteristics;
			}
			return item;

		})
	}

	return (
		<div className='comp'>
			<div className="container">
				<Hamburger />
				<BreadCumps
					items={
						[
							{
								link: "/",
								name: 'Главная'
							},
							{
								link: "/comparison",
								name: 'Сравнение'
							},
						]
					}
				/>
				<Title title="Сравнение" style={{ margin: '-60px 0 0 0' }} />
				<div className="search-comparison">
					<div className="comp-items">
						{
							comparisonItemsMutated.map(item => {
								console.log('COMPI', item)
								return (
									<Card
										deleteIcon={imgImport('cardDetails', 'cross.png')}
										characs={item.characteristics}
										charcster={item.characteristic}
										id={item.id}
										key={item.id}
										title={item.title}
										image={item.image}
										price={item.price}
										salePrice={item.salePrice ? item.salePrice : ''}
										article={item.setNumber}
										created={item.created_at}
										inCompare={inCompare}
										setDeleteCompare={setDeleteCompare}
										deleteCompare={deleteCompare}
									/>
								)
							})
						}
					</div>
				</div>
			</div>
		</div>
	)
}

export default ScrollWrapper(Comparison);