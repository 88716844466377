import React from 'react'
import { imgImport } from '../../helpers/helper';
import { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import { fetchProducts } from "../../store/actions";
import Card from '../../components/Card'
import BreadCumps from '../../components/BreadCumps'
import Hamburger from '../../components/Hamburger';
import Pagination from '../../components/Pagination';
import Title from '../../components/Title'
import { filterAction } from "../../store/actions";
import { useNavigate } from "react-router-dom";
import { ScrollWrapper } from '../../components/ScrollWrapper';
import '../../style/pages/catalog.css'
import { useLocation } from 'react-router';
const Catalog = () => {
	const location = useLocation()
	const [showSubCat, setShowSubCat] = useState(false)
	const [showDropdown, setShowDropdown] = useState(false)
	const [showCats, setShowCats] = useState(false)
	const [showCatsId, setShowCatsId] = useState(null)
	const pageProductsCount = 9;

	const [filter, setFilter] = useState({
		category_id: '',
		subcategory_id: '',
		event_id: '',
		increase: '',
		decrease: '',
		available: '',
		new: '',
		discount: '',
		popularity: ''
	})
	const [chosenFilter, setChosenFilter] = useState('Фильтр не выбран')
	const [catalog, setCatalog] = useState({});
	const [cats, setCats] = useState({})
	const [checkers, setCheckers] = useState({
		category: '',
		subcategory: '',
		event: ''
	});
	const [paginationDetails, setPaginationDetails] = useState(null);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [active, setActive] = useState({
		all: false,
		discounts: false,
		available: false
	})

	useEffect(() => {
		dispatch(fetchProducts()).then(res => {
			setCats({ cats: res[0] })
		})
	}, [location.search])

	useEffect(() => {

		var reg = new RegExp('^[0-9]$');
		let test = reg.test(window.location.pathname.substr(-1))
		if (location.state && location.state.length > 0) {
			const formatter = { data: location.state }
			setCatalog({ ...catalog, products: formatter })
			setPaginationDetails(location.state);
			setCheckers({
				...checkers,
				'category': '',
				'subcategory': '',
				'event': ''
			})
			return
		}
		if (location.pathname.includes('event') && test) {
			filterAll('event_id', location.pathname.replace(/\D/g, ''))
			return
		}
		if (!location.pathname.includes('sub-cat') && !location.pathname.includes('event') && test) {
			filterAll('category_id', location.pathname.replace(/\D/g, ''))
			navigate(``)
			return
		}
		if (location.pathname.includes('sub-cat') && test) {
			filterAll('subcategory_id', location.pathname.replace(/\D/g, ''))
			return
		}
		if (!reg.test(location.pathname.substr(-1)) && !test) {
			dispatch(fetchProducts()).then(res => {
				setCatalog({ products: res[1] })
				setPaginationDetails(res[1]);
			})
			return
		}
	}, [location.search]);

	const onPaginate = ({ currentPage }) => {
		if (currentPage <= 0 || !currentPage) return;
		navigate(currentPage);
		var reg = new RegExp('^[0-9]$');
		let test = reg.test(location.pathname.substr(-1))
		if (location.state && location.state.length > 0) {
			const formatter = { data: location.state }

			setCatalog({ ...catalog, products: formatter })
			setPaginationDetails(location.state);
			return
		}
		if (location.pathname.includes('event') && test) {
			filterAll('event_id', location.pathname.substr(-2), currentPage)
			return
		}
		if (!location.pathname.includes('sub-cat') && !location.pathname.includes('event') && test) {
			filterAll('category_id', location.pathname.substr(-2), currentPage)
			return
		}
		if (location.pathname.includes('sub-cat') && test) {
			filterAll('subcategory_id', location.pathname.substr(-2), currentPage)
			return
		}
		if (!test) {
			filterAll(null, null, currentPage)
			return
		}
	}

	const filterAll = (type, id, page, red, filterText) => {

		if (filterText) {
			setShowDropdown(!showDropdown)
			setChosenFilter(filterText)
		}
		if (red) {
			let prevActive = active
			for (let i in active) {
				if (i !== red) {
					prevActive[i] = false
				}
			}
			setActive({ ...active, ...prevActive, [red]: true })
		}
		if (!type && !id) {

			dispatch(fetchProducts(page)).then(res => {
				setCatalog({ products: res[1] })
				setPaginationDetails(res[1]);
				navigate('/catalog')
				setFilter({ ...filter, category_id: '', subcategory_id: '', event_id: '', })
			})
		}
		let newItem = filter
		if (type?.includes('id')) {

			// (filter)
			for (let i in filter) {
				if (i.includes('id') && i == type) {
					newItem[i] = id
				} if (i.includes('id') && i != type) {
					newItem[i] = ''

				}
			}
		} else if (!type?.includes('id')) {

			for (let i in filter) {
				if (!i.includes('id') && i != type) {
					newItem[i] = ''
					// newItem[i] = 0;
				}
				if (!i.includes('id') && i == type) {
					newItem[i] = 1;
				}
			}
		}
		setFilter({ ...filter, ...newItem })
		dispatch(filterAction(filter, page)).then(res => {
			setCatalog(res)
			setPaginationDetails(res.products)
		})

	}

	return (
		<div className="catalog-page" style={{ marginBottom: '30px' }}>
			<div className="container" onClick={() => showSubCat && setShowSubCat(false)}>
				<Hamburger />
				<Title title="Каталог товаров" style={{ margin: '-80px 0 0 0' }} />
				<div className="title-filters">
					<div className="filter-dropdown-cont">
						<BreadCumps
							items={
								[
									{
										link: "/",
										name: 'Главная'
									},
									{
										link: "/catalog",
										name: 'Каталог'
									},
								]
							}
						/>
						<div className="filter-dropdown">
							<div onClick={() => setShowDropdown(!showDropdown)} className="filter-dropdown-btn">{chosenFilter} <img src={imgImport('catalog', 'triangle.png')} alt="" /></div>
							{
								showDropdown &&
								<ul className='dropdown-filters'>
									<li onClick={() => filterAll('popularity', null, null, null, 'По популярности')}>По популярности</li>
									<li onClick={() => filterAll('new', null, null, null, 'По новизне')}>По новизне</li>
									<li onClick={() => filterAll('increase', null, null, null, 'По повышению цен')}>По повышению цен</li>
									<li onClick={() => filterAll('decrease', null, null, null, 'По понижению цен')}>По понижению цен</li>
								</ul>
							}
						</div>
					</div>
					<div className="catalog-filters">
						<div onClick={() => { filterAll('all', null, 1, 'all') }} className={'all-filter' + ' ' + (active.all && 'filters-active')}>Все</div>
						<div onClick={() => filterAll('discount', null, 1, 'discounts')} className={'discounts-filter' + ' ' + (active.discounts && 'filters-active')}>Акции</div>
						<div onClick={() => filterAll('available', null, 1, 'available')} className={'available-filter' + ' ' + (active.available && 'filters-active')}>В наличии</div>
					</div>
				</div>

				{
					(cats.cats || catalog.products) &&
					<div className="catalog-inner">
						<div className="catalog-filter">
							<div className="sidebar">
								{
									window.innerWidth > 821 ?
										<div className="catalog-title">
											<img src={imgImport('mainPage', 'three-lines.svg')} alt="" />
											<p>Каталог товаров</p>
										</div>
										:
										<div className="catalog-title catalog-incatalog">
											<div className="catalog-conatiner-first">
												<img src={imgImport('mainPage', 'three-lines.svg')} alt="" />
												<p>Каталог товаров</p>
											</div>
											<img
												className='arrow-down-catalog'
												style={{ cursor: 'pointer', transform: showCats ? 'rotate(180deg)' : '', margin: '0 1.5rem 0 0' }}
												src={imgImport('mainPage', 'arrow-down.png')}
												alt=""
												onClick={() => setShowCats(!showCats)}
											/>
										</div>

								}
								<div className="catalog">
									{(cats.cats && cats.cats.length > 0) && cats.cats.map(item => {
										return (
											<div style={{ display: showCats || window.innerWidth > 821 ? 'flex' : 'none' }} key={item.id} className="category">
												<div className="cat-title-cont">
													<img className="cat-icon" src={'https://api.pittools.kz/storage/' + item.image} alt="" />

													<p style={{ color: filter.category_id == item.id && 'red' }} onClick={() => {

														filterAll('category_id', item.id)
														navigate(`/catalog/${item.id}`)
													}}
														className="cat-title">{item.title}</p>
													{item.subcategories.length > 0 && (<img
														onClick={() => {

															navigate(`/catalog`)
															setShowCatsId(item.id)
															setShowSubCat(!showSubCat)
														}}
														className="cat-arrow"
														src={imgImport('mainPage', 'arrow-right.png')} alt=""
													/>
													)}
												</div>
												{window.innerWidth < 821 && (showCatsId === item.id && showSubCat) && item.subcategories.map(subitem => {
													return (
														<div key={subitem.id} className={`subcatalog-mob${subitem.id} subcatalog-mob`}>

															<div style={{ color: filter.subcategory_id == subitem.id && 'red' }} onClick={() => {

																filterAll('subcategory_id', subitem.id)
															}} className="subcat">
																{subitem.title}
															</div>


														</div>
													)
												})
												}
											</div>
										)
									})
									}
								</div>
							</div>
						</div>
						<div className="items">
							<div className="items-inner">
								{cats.cats && cats.cats.map(item => {
									if (item.subcategories.length > 0) {
										return (
											window.innerWidth > 821 &&
											<div key={item.id} style={{ overflowY: "scroll", left: '-24px', display: (showCatsId === item.id && showSubCat) ? 'block' : 'none' }} className={`subcatalog${item.id} subcatalog`}>
												{item.subcategories && item.subcategories.map(subitem => {
													return (
														<div style={{ color: filter.subcategory_id === subitem.id && 'red' }} key={subitem.id} onClick={() => {
															filterAll('subcategory_id', subitem.id)
															navigate(`/catalog/sub-cat=${subitem.id}`)
														}} className="subcat">
															{subitem.title}
														</div>
													)
												})}
											</div>
										)
									}
								})}
								{catalog.products && catalog.products.data.length > 0 ?
									catalog.products.data.map(item => {
										return (
											<div className={catalog?.products?.data?.length > 9 ? 'elems' : ''}>
												<Card
													id={item.id}
													key={item.id}
													title={item.title}
													rating={item.rating}
													image={item.image}
													price={item.price}
													salePrice={item.salePrice ? item.salePrice : ''}
													article={item.setNumber}
													created={item.created_at}
												/>
											</div>
										)
									}
									) : <span>Товар отсутствует</span>
								}
							</div>
						</div>
					</div>
				}
				<div className="catalog_pagination">
					<div className="catalog_pagination_inner">

						{

							paginationDetails && paginationDetails.last_page > 1 ?
								<>
									{paginationDetails.total <= 5 ? null :
										<img className='pagination-left' src={imgImport('pagination', 'arrow-left.png')} />}
									<Pagination
										totalRecords={paginationDetails.total}
										pageLimit={pageProductsCount}
										pageNeighbours={1}
										onPageChanged={onPaginate}
										currentPage={paginationDetails.current_page}
									/>
									{paginationDetails.total <= 5 ? null :
										<img className='pagination-right' src={imgImport('pagination', 'arrow-right.png')} />}
								</>
								: ''
						}
					</div>
				</div>
			</div>
		</div>
	)
}

export default ScrollWrapper(Catalog);
