import React from 'react'
import { useState, useEffect } from 'react';
import { imgImport } from '../helpers/helper';
import { useNavigate, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { _storage, _url } from '../helpers/helper';
import { useLocation } from 'react-router';
import '../style/components/card.css';
import { isInCartAction, isInCompoAction, isInFavsAction } from '../store/reducers';

const Card = (props) => {
	const location = useLocation();
	const [isFav, setIsFav] = useState(false)
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const rating = []
	const notrated = []
	const [productId, setProductId] = useState({ product_id: props.id })
	const [isInCart, setIsInCart] = useState(false)
	const [isInCompare, setIsInCompare] = useState(false);
	const [deleted, setDeleted] = useState(true);

	for (let i = 1; i < props.rating; i++) {
		rating.push(i)
	}
	for (let i = rating.length; i < 5; i++) {
		notrated.push(i)
	}
	useEffect(() => {
		props.setIsFavChanged && props.setIsFavChanged(!props.setIsFavChanged)
	}, [])

	useEffect(() => {
		// (props)
		if (_storage.get('smartg-token') && _storage.get('cart')) {
			JSON.parse(_storage.get('cart')).forEach(item => {
				(item.id == props.id) && setIsInCart(true)
			})
		}
		if (_storage.get('smartgfav')) {
			(_storage.get('smartgfav'))
			let favs = _storage.get('smartgfav')
			favs.includes(productId.product_id) && setIsFav(true)
		}
		if (_storage.get('smartg-comp')) {
			(_storage.get('smartg-comp'))
			let favs = _storage.get('smartg-comp')
			favs.includes(productId.product_id) && setIsInCompare(true)
		}
	}, [])

	const addFav = () => {
		let item = {
			id: productId.product_id,
			count: 1
		}
		dispatch(isInFavsAction(item.id));

		if (!_storage.get('smartgfav')) {
			setIsFav(true)
			_storage.set('smartgfav', JSON.stringify([item]))
		} else {
			let favs = [];
			let JsonFavs = JSON.parse(_storage.get('smartgfav'));
			favs = JsonFavs;
			favs.push(item)
			_storage.set('smartgfav', JSON.stringify(favs))
			setIsFav(true)
		}
	}

	const removeFav = (id) => {
		let storage = JSON.parse(_storage.get('smartgfav'));
		let newStorage = [];
		storage.forEach((item) => {
			if (item.id != id) {
				newStorage.push(item);
			}
		});
		setIsFav(false)
		dispatch(isInFavsAction(id));
		props.setDeletedFavs(!props.deletedFavs);
		_storage.set('smartgfav', JSON.stringify(newStorage));
	}

	const addToBasket = () => {
		let item = {
			id: props.id,
			count: 1
		}
		dispatch(isInCartAction(item));

		if (!_storage.get('cart')) {
			_storage.set('cart', JSON.stringify([item]))
			setIsInCart(true)
		} else {
			let cart = []
			let jsonCart = JSON.parse(_storage.get('cart'))
			cart = jsonCart
			cart.push(item)
			_storage.set('cart', JSON.stringify(cart))
			setIsInCart(true)
		}
	}

	const addToCompain = () => {
		let item = {
			id: props.id,
			count: 1
		}
		dispatch(isInCompoAction(item));
		if (!_storage.get('smartg-comp')) {
			_storage.set('smartg-comp', JSON.stringify([item]))
			setIsInCompare(true);
		} else {
			let compain = [];
			let jsonCompain = JSON.parse(_storage.get('smartg-comp'));
			compain = jsonCompain;
			compain.push(item);
			_storage.set('smartg-comp', JSON.stringify(compain));
			setIsInCompare(true);
		}
	}

	const deleteComp = (id) => {
		dispatch(isInCompoAction(id));
		setIsInCompare(false);
		let storage = JSON.parse(_storage.get('smartg-comp'));
		let newStorage = [];
		storage.forEach((item) => {
			if (item.id != id) {
				newStorage.push(item);
			}
		});
		setDeleted(false);
		props.setDeleteCompare(!props.deleteCompare);
		_storage.set('smartg-comp', JSON.stringify(newStorage));
	};

	return (
		<div className='card_main_block'>
			{deleted ?
				<div className='card'>

					<div className="card-wrapperden">
						{
							(props.salePrice || new Date().getMonth() == toString(props.created).split('T')[0].split('-')[1]) &&
							<div className="modals" style={{ marginTop: '1rem' }}>
								{
									props.salePrice && <div className="sale-modal">Скидка</div>
								}
								{
									new Date().getMonth() == toString(props.created).split('T')[0].split('-')[1] &&
									<div className="new-modal"
										style={{ marginLeft: props.salePrice ? '0.5rem' : '0' }}>Новинка</div>
								}
							</div>
						}
						{
							props.deleteIcon &&
							<img className='delete_main_icon'
								onClick={() => {
									deleteComp(props.id)
								}} src={props.deleteIcon} alt="" />
						}
						<Link to={`/product/${props.id}`} style={{ textDecoration: 'none' }}>
							<p className='product_photo_center'>
								<div className="item-pic-container">
									{
										Array.isArray(props.image) ? <div>
											{
												<img className='item-pic' src={_url + 'storage/' + props.image[0]} alt="" />
											}</div> : <img className='item-pic' src={_url + 'storage/' + props.image} alt="" />
									}
								</div>
							</p>
							{
								props.rating &&
								<div className="rating">
									{
										rating.map(item => {
											return (
												<img key={item} src={imgImport('mainPage', 'red-star.png')} alt="" />
											)
										})
									}
									{
										notrated.map(item => {
											return (
												<img key={item} src={imgImport('mainPage', 'gray-star.png')} alt="" />
											)
										})
									}
								</div>
							}
							<p className="_comparase_product_title" style={{ color: '#414141' }}>{props.title}</p>
						</Link>
						{props.inCompare ?
							<div className="_comparase_charac_null">
								{
									props.charcster?.map(item => {
										let value = '';
										props?.charcster?.forEach(propsItem => {
											if (item.name === propsItem?.name) value = propsItem?.value
										})

										return (
											<div key={item.id} className="_comparase_charac_null_first_block">
												<div className="_comparase_charac_null_articule">
													{`${item.name} `}
												</div>
												<div className='_comparase_charac_null_value'>
													<span className="_compare_null_values">{value}</span>
												</div>
											</div>
										);
									})
								}
							</div>
							: ''
						}
						<div className="cart-setnum">
							<div className="cart">
								<div className="prices">
									<p
										className={props.salePrice ? 'old-price' : 'card-price'}>{JSON.stringify(props.price)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ₸</p>
									{props.salePrice && <p
										className='card-sale'> {JSON.stringify(props.salePrice)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ₸</p>}
								</div>
								<div className="btns">
									{isInCompare ?
										<div className="compare_right">
											<img
												src={imgImport('card', 'right.png')} alt="" onClick={() => navigate('/comparison')} />
										</div>
										:
										<div className="compare">
											<img onClick={() => {
												if (!location.pathname.includes('comparison')) {
													addToCompain()
												} else {
													return
												}
											}}
												src={imgImport('card', 'scale.png')} alt="" />
										</div>
									}
									{
										isFav
											? <img className='isFav' onClick={() => {
												if (location.pathname.includes('favorite')) {
													removeFav(props.id);
												} else {
													navigate(`/favorite`);
												}
											}}
												src={imgImport('card', 'heart.png')} alt="" />
											: <img className='isFav' onClick={() => addFav()}
												src={imgImport('card', 'heart-outline.png')} alt="" />
									}
									{
										isInCart ?
											<img className='isCart' onClick={() => navigate('/basket')}
												src={imgImport('card', 'gray-cart.png')} alt="" />
											: <img className='isCart' onClick={() => addToBasket()}
												src={imgImport('mainPage', 'cart-btn.png')} alt="" />
									}
									{/* <img onClick={()=>addToBasket()} src={isInCart ?imgImport('cardDetails','check.png') : imgImport('mainPage','cart-btn.png')} alt="" /> */}
								</div>
							</div>
							<p className='modell' style={{ color: '#c2bfcd' }}>Модель: {props.article}</p>
						</div>
					</div>
				</div>
				: ''}
		</div>
	)
}

export default Card
