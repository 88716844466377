import {
	mainPageState,
	aboutPageState,
	catalogState,
	userState,
	authState,
	favsState,
	isInCartState
} from "./states";
import { actions } from "./constants";
export const MainPageReducer = (state = mainPageState, { type, payload }) => {
	switch (type) {
		case actions.MainPage:
			return { ...state, contents: payload };

		default:
			return state;
	}
}
export const AboutPageReducer = (state = aboutPageState, { type, payload }) => {
	switch (type) {
		case actions.aboutPage:
			return { ...state, contents: payload };

		default:
			return state;
	}
}
export const CatalogPageReducer = (state = catalogState, { type, payload }) => {
	switch (type) {
		case actions.PRODUCT_FETCH:
			const new_payload = {
				...payload,
				products: {
					data: [...payload.data]
				}
			}
			return { ...state, ...new_payload };
		default:
			return { ...state }
	}
}

export const catalogFilterReducer = (state = catalogState, { type, payload }) => {
	switch (type) {
		case actions.filterCat:
			const new_payload = {
				...payload,
			}
			return { ...state, ...new_payload };
		default:
			return { ...state }
	}
}
export const catalogSubFilterReducer = (state = catalogState, { type, payload }) => {
	switch (type) {
		case actions.filterSubCat:
			const new_payload = {
				...payload,
			}
			return { ...state, ...new_payload };
		default:
			return { ...state }
	}
}
export const userReducer = (state = userState, { type, payload }) => {
	switch (type) {
		case actions.USER_ACTION:
			return { ...state, user: payload };
		case actions.REGISTER_ACTION:
			return { ...state, user: payload };
		case actions.GET_USER:
			return { ...state, ...payload }
		default:
			return state;
	}
}
export const AuthReducer = (state = authState, { type, payload }) => {
	switch (type) {
		case actions.AUTHED:
			return { ...state, isAuthenticated: payload }
		case actions.LOGOUT:
			return { ...state, isAuthenticated: payload }
		default:
			return { ...state };
	}
}
export const favsReducer = (state = favsState, { type, payload }) => {
	switch (type) {
		case actions.getFavs:
			return { ...state, favs: payload };
		default:
			return state;
	}
}

//BASKET
const IS_IN_CART = 'IS_IN_CART';

const initialState = {
	item: []
}

export const isInCartReducer = (state = initialState, action) => {
	switch (action.type) {
		case IS_IN_CART: {
			return { ...state, item: action.item }
		}
		default:
			return state;
	}
}

export const isInCartAction = (item) => ({ type: IS_IN_CART, item });

// FAVS
const IS_IN_FAVS = 'IS_IN_FAVS';

const initialStateFavs = {
	favster: []
}

export const isInFavsReducer = (state = initialStateFavs, action) => {
	switch (action.type) {
		case IS_IN_FAVS: {
			return { ...state, favster: action.favster }
		}
		default:
			return state;
	}
}

export const isInFavsAction = (favster) => ({ type: IS_IN_FAVS, favster });

// COMPARISON
const IS_IN_COMPO = 'IS_IN_COMPO';

const initialStateCompo = {
	compos: []
}

export const isInCompoReducer = (state = initialStateCompo, action) => {
	switch (action.type) {
		case IS_IN_COMPO: {
			return { ...state, compos: action.compos }
		}
		default:
			return state;
	}
}

export const isInCompoAction = (compos) => ({ type: IS_IN_COMPO, compos });