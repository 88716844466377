import React from 'react'
import '../style/components/header.css'
import { imgImport, _storage } from '../helpers/helper.js'
import { Link, useNavigate } from 'react-router-dom'
import { searchAction } from '../store/actions'
import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router'
import { useSelector } from 'react-redux'
import { isInCartSelector, isInCompoSelector, isInFavsSelector } from '../store/selectors'
const Header = () => {
	const cartLength = useSelector(isInCartSelector);
	const favsLength = useSelector(isInFavsSelector);
	const compoLength = useSelector(isInCompoSelector);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const [search, setSearch] = useState('');
	const [counter, setCounter] = useState(0);
	const [favsCounter, setFavsCounter] = useState(0);
	const [compareCounter, setCompareCounter] = useState(0);

	const sendSearch = () => {
		dispatch(searchAction({ search })).then(res => res.data.products.length > 0 && navigate(`/catalog?search=${search}`, { state: res.data.products }))
	}

	useEffect(() => {
		const storage = JSON.parse(_storage.get('cart'));
		setCounter(storage?.length);
	}, [cartLength.item]);

	useEffect(() => {
		const storageFavs = JSON.parse(_storage.get('smartgfav'));
		setFavsCounter(storageFavs?.length);
	}, [favsLength]);

	useEffect(() => {
		const storageCompos = JSON.parse(_storage.get('smartg-comp'));
		setCompareCounter(storageCompos?.length);
	}, [compoLength.compos])

	const onSubmitHandler = () => {
		setSearch({
			search: '',
		})
	}

	return (
		<div className="header-component">

			<header>
				<div className="container">
					<div className="header-wrap">
						<div className="phone-numbers">
							<img src={imgImport('header', 'phone.png')} alt="" />
							<a href="tel:+77273465566" className='first_number'>+7 (727) 346 55 66</a>
							<div className="line"></div>
							<a href="tel:+77273465566" className='second_number'>+7 (727) 346 55 66(Оптовикам)</a>
						</div>
						<div className="service-center">
							<p className='below_service_number'>Сервис-центр:</p>
							<a className='below_first_number' href="tel:+77072609083" >+7 (707) 260-90-83</a>
							<a className='below_second_number' href="tel:+77072609083" >+7 (775) 540 00 04</a>
						</div>
						<div className="user">
							<Link to="/basket">
								<div className={location.pathname.includes('basket') ? 'img-red-border' : 'img-border'}>
									<img className='img_border_img' src={imgImport('header', 'cart.png')} alt="" />
									{
										counter !== 0 &&
										<span className={counter === undefined || 0 ? '_basket_integraition_none' : '_basket_integraition'}>
											{counter}
										</span>
									}
								</div>
							</Link>
							<Link to="/favorite">
								<div className={location.pathname.includes('favorite') ? 'img-red-border' : 'img-border'}>
									<img className='img_border_i' src={imgImport('header', 'heart.png')} alt="" />
									{
										favsCounter !== 0 &&
										<span className={favsCounter === undefined || 0 ? '_basket_integraition_none' : '_basket_integraition'}>
											{favsCounter}
										</span>}
								</div>
							</Link>
							<Link to="/comparison">
								<div className={location.pathname.includes('comparison') ? 'img-red-border' : 'img-border'}>
									<img className='img_border_m' src={imgImport('header', 'scale.png')} alt="" />
									{
										compareCounter !== 0 &&
										<span className={compareCounter === undefined || 0 ? '_basket_integraition_none' : '_basket_integraition'}>
											{compareCounter}
										</span>}
								</div>
							</Link>
							<Link to="/cabinet/profile">
								<div className={location.pathname.includes('profile') ? 'img-red-border_g' : 'img-border_g'}>
									<img className='img_border_g_p' src={imgImport('header', 'user.png')} alt="" />
								</div>
							</Link>
						</div>
					</div>
				</div>
			</header>
			<div className="container">
				<div className="mid-bar">
					<div className="logo">
						<Link to="/">
							<img src={imgImport('header', 'Logo.png')} alt="" />
						</Link>
						<div className="official-site">
							<p>Официальный</p>
							<p>сайт P.I.T.</p>
							<p>в Казахстане</p>
						</div>
					</div>
					<nav className="nav">
						<div className="nav-link">
							<p style={{ cursor: 'pointer', color: location.pathname == '/about' ? 'red' : '#414141' }} onClick={() => navigate('about')}> О компании</p>

						</div>
						<div className="nav-link">
							<p style={{ cursor: 'pointer', color: location.pathname == '/wholesale' ? 'red' : '#414141' }} onClick={() => navigate('wholesale')}>Оптовые продажи</p>
						</div>
						{/* <div className="nav-link">
                            <p>Запчасти</p>
                        </div> */}
						<div className="nav-link">
							<p style={{ cursor: 'pointer', color: location.pathname == '/discounts' ? 'red' : '#414141' }} onClick={() => navigate('discounts')}>Акции</p>
						</div>
						<div className="nav-link">
							<p style={{ cursor: 'pointer', color: location.pathname == '/service' ? 'red' : '#414141' }} onClick={() => navigate('service')}>Сервис</p>
						</div>
					</nav>
					<div className="search-container">
						<input onInput={(e) => setSearch(e.target.value)} className="search-input" placeholder="Поиск" type="text" />
						<img style={{ cursor: 'pointer' }} onSubmit={() => onSubmitHandler()} onClick={() => sendSearch()} src={imgImport('header', 'lupa.png')} alt="" />
					</div>
				</div>
			</div>
		</div>
	)
}

export default Header
